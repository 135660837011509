<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="5">
            <h4 id="traffic" class="card-title mb-0">Módulos</h4>
            <div class="small text-muted">Configuración de módulos para del proyecto</div>
          </b-col>

          <b-col sm="7" class="d-none d-md-block">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">

        <b-row v-if="table.mostrarFiltros">
          <b-col sm="4">
            <b-form-group>
              <b-input-group>
                <b-form-input type="text" size="sm" placeholder="Ingrese su busqueda" v-model="table.filter"></b-form-input>
                <b-input-group-prepend>
                  <b-button variant="dark" size="sm" :disabled="!table.filter" @click="table.filter = ''">
                    <i class="fa fa-filter"></i> Filtrar
                  </b-button>
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12">
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"
                    :filter="table.filter">

                  <template v-slot:cell(name)="data">
                      {{data.item.name.toUpperCase()}}
                  </template>

                  <template v-slot:cell(description)="data">
                      <b>{{data.item.title.substring(data.item.title.indexOf(".") + 1)}}</b><br>
                      {{data.item.description}}
                  </template>

                  <template v-slot:cell(dependence)="data">
                    <div v-html="getDependences(data.item.dependence)"></div>
                  </template>

                  <template v-slot:cell(active)="data">
                      <b-badge variant="success" v-if="data.item.active">Activo</b-badge>
                      <b-badge variant="danger" v-if="!data.item.active">Inactivo</b-badge>
                  </template>

                  <template v-slot:cell(f_action)="data">
                    <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                      <b-dropdown-item @click="activarModule(data.item)" v-if="!data.item.active">
                        <i class="fa fa-check-square-o" style="color:green"></i> Activar
                      </b-dropdown-item>
                      <b-dropdown-item @click="desactivarModule(data.item)" v-if="data.item.active">
                        <i class="fa fa-square-o" style="color:red"></i> Desactivar
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>

            </b-table>
          </b-col>
        </b-row>

      </b-card>

      <b-card>
        <b-button type="button" class="float-right" variant="dark" @click="configurate()">
          Guardar
        </b-button>
      </b-card>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'

  export default {
    data: () => {
      return {
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID'},
            {key: 'name', label: 'Nombre'},
            {key: 'description', label: 'Descripción'},
            {key: 'active', label: 'Activar'},            
            {key: 'f_action', label:''},
          ],
          filter: null,
          mostrarFiltros: false,
        },
        arrModules: [],
      }
    },
    mounted() {
      this.cargar()
    },
    methods: {
      cargar () {
        var result = serviceAPI.obtener()

        result.then((response) => {
          var data = response.data
          this.table.items = data
          this.arrModules = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });
      },
      getDependences (dependence) {
        let arrDependences = dependence.split(",")
        var result = ""
        arrDependences.forEach(element => {
          if(element) {
            result = result + "<span class='badge badge-secondary mr-1 badge-module-custom'>" + this.getNameDependence(element) + "</span>"
          }
        });
        return result
      },
      getNameDependence(id) {
        var result = ""
        this.arrModules.forEach(element => {
          if(element.id == id) {
            result = element.name
          }
        });
        return result
      },
      activarModule (item) {
        item.active = true
        /*if(item.dependence){
          this.activarDependences(item)
        }*/
      },
      activarDependences(item) {
        var result = item.dependence.split(",")

        result.forEach(dependence_id => {
          this.arrModules.forEach(element => {
            if(element.id == dependence_id) {
              element.active = true
              if(element.dependence){
                this.activarDependences(element)
              }
            }
          })
        })
      },
      desactivarModule (item) {
        item.active = false
        //this.desactivarDependences(item)
      },
      desactivarDependences (item) {        
        item.active = false                
        this.arrModules.forEach (element => {            
          if(element.dependence) {
            var arrDependences = element.dependence.split(",")

            arrDependences.forEach(id => {
              if(id == item.id) {
                element.active = false
              }
            })
          } 
        })
      },
      configurate() {
        let loader = this.$loading.show();

        var data = []
        this.arrModules.forEach(element => {
          data.push({id:element.id, active:element.active})
        })

        var result = serviceAPI.configurate(data);

        result.then((response) => {
          loader.hide()
          this.cargar()
          this.$awn.success("Configuración guardada");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      }
    }
  }
</script>
<style>
  .badge-module-custom {
    font-size: 12px;
  }
</style>
