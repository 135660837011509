import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "modules" + Session.getToken();
    return instance.get(url);
  },
  configurate(data) {
    var params = data
    var url = ConfigAPI.baseURL + "modules/configurate" + Session.getToken();
    return instance.post(url,params);
  }
}

export default servicesAPI;
